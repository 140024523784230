import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import Login from "@/views/LoginView.vue";
import Device from "@/views/DeviceView.vue";
import PageNotFound from "@/views/PageNotFoundView.vue";
import store from '@/store/index';

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/panel/:panel_num",
        name: "panel",
        // meta: {
        //     requiresAuth: true,
        // },
        component: Device,
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.token) {
            next({ name: 'login' });
        } else {
            next(); // go to wherever I'm going
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
})

export default router;
