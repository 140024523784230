<template>
    <div class="login-navbar">
        <span class="title">artloop</span>
        <button class="btn-home" @click="goToHome()">Início</button>
    </div>

    <div class="page" :style="{ backgroundColor: pageColor }">
        <div class="card" :style="{ backgroundColor: cardColor }">
            <div class="title">
                <span>Painel Artloop</span>
            </div>
            <span v-if="loginError" class="login-error">
                {{ loginError }}
            </span>
            <form class="form" @submit.prevent="login">
                <div class="form-group">
                    <span for="email">Email </span>
                    <input :class="inputClass" type="email" v-model="email" id="id-email" :placeholder="emailError"
                        @focus="clearEmailError" />
                </div>
                <div class="form-group">
                    <span for="password">Senha </span>
                    <input :class="inputClass" type="password" v-model="password" id="id-password"
                        :placeholder="passwordError" @focus="clearPasswordError" />
                </div>
                <button type="submit" id="id-btn-login" class="button">
                    Entrar
                </button>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from "@vue/reactivity";
import { loginService } from '@/services/painel_api'
import { encrypt } from "@/utils/crypt";
import { is_not_empty_str } from "@/utils/functions";

const store = useStore();
const router = useRouter();
const loading = ref(false);

const email = ref('');
const password = ref('');
const emailError = ref(null);
const passwordError = ref(null);
const loginError = ref(null);

const pageColor = ref("#202020");
const cardColor = ref("#dddddd");


const isValidEmail = (email) => {
    if (!is_not_empty_str(email)) {
        return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(email);
}

const login = async () => {
    loading.value = true;
    emailError.value = null;
    passwordError.value = null;
    loginError.value = null;

    // Validar email
    if (!is_not_empty_str(email.value)) {
        emailError.value = "Email é obrigatório";
    } else if (!isValidEmail(email.value)) {
        emailError.value = "Email inválido.";
    }

    // Validar senha
    if (!is_not_empty_str(password.value)) {
        passwordError.value = "Senha é obrigatória";
    }

    if (emailError.value || passwordError.value) {
        loading.value = false;
        return;
    }

    try {
        const response = await loginService(
            email.value,
            password.value,
        );
        const { auth_token } = response.data;
        store.dispatch('setUserLogin', { email: email.value, password: encrypt(password.value) });
        store.dispatch('setToken', encrypt(auth_token));
        goToHome();
    } catch (error) {
        loginError.value = "Usuário ou senha incorretos";
    }
    loading.value = false;
}

const clearEmailError = () => {
    emailError.value = null;
}

const clearPasswordError = () => {
    passwordError.value = null;
}

const goToHome = () => {
    router.push({ name: "home" });
}

const inputClass = computed(() => {
    return (emailError.value || passwordError.value) ? 'input__error' : 'input';
})
</script>


<style lang="scss" scoped>
.login-navbar {
    display: flex;
    height: 50px;
    background-color: #005b56;
    align-items: center;
    justify-content: space-between;

    .title {
        margin-left: 50px;
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 25px;
    }

    .btn-home {
        background-color: #fffdd2;
        /* Green */
        border: none;
        color: black;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        margin-right: 20px;
        transition-duration: 0.4s;
        border-radius: 5px;
    }

    .btn-home:hover {
        background-color: #ffaa44;
        /* Green */
        color: white;
    }
}

.page {
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 20px 30px;
        background-color: white;

        .title {
            padding: 20px 50px;
            padding-bottom: 30px;
            font-size: 40px;
            font-weight: 600;
            color: #005b56;
        }

        .login-error {
            color: red;
            font-size: large;
            font-weight: 600;
        }

        .form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .form-group {
                width: 350px;
                padding: 0;
                display: flex;
                flex-direction: column;

                input {
                    width: 100%;
                    padding: 12px 20px;
                    margin: 8px 0;
                    display: inline-block;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-sizing: border-box;
                }

                .input {
                    &__error::placeholder {
                        color: red !important;
                    }
                }

                v-text-field {
                    padding-top: 5px;
                }

                span {
                    font-size: medium;
                    font-weight: 400;
                }
            }

            .button {
                margin: 10px 0px;
                display: inline-block;
                padding: 10px 30px;
                font-size: 15px;
                font-weight: 700;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                outline: none;
                color: white;
                background-color: #005b56;
                border: none;
                border-radius: 5px;
            }

            .button:hover {
                background-color: #227777;
                color: white;
            }
        }
    }
}
</style>