<template>
    <div v-if="isLoading">
        <div class="loading_page">
            <span class="loader"></span>
            <Logo class="logo" :size="logoSize" :transform="logoTransform" :color="logoColor" />
        </div>
    </div>
    <div v-else-if="isDisabled">
        <div class="disable_page">
            <Logo class="logo" :size="logoSize" :transform="logoTransform" :color="logoColor" />
            <p :style="{ marginTop: '300px' }">
                Visualização desabilitada, se este comportamento não for o esperado
                entre em contato com o setor de atendimento ao cliente.
            </p>
        </div>
    </div>
    <div v-else-if="alreadyConnected">
        <div class="disable_page">
            <Logo class="logo" :size="logoSize" :transform="logoTransform" :color="logoColor" />
            <p :style="{ marginTop: '300px' }">
                Este dispositivo já está conectado ao Painel Artloop.
                Em caso de dúvida, entre em contato com o setor de atendimento ao cliente.
            </p>
        </div>
    </div>
    <div v-else class="device_page" :style="{
        backgroundColor: bgColor,
        backgroundImage: bg_image && op_mode != 'FULL_SCREEN_POSTERS' ? `url(${bg_image})` : null,
        height: '100%',
        backgroundSize: 'cover',
    }">
        <div class="device_panel_header"
            :style="{ background: headerColor, color: txtHeaderColor, display: ['POSTERS', 'FULL_SCREEN_POSTERS'].includes(op_mode) ? 'none' : 'flex' }">
            <div class="div-header">
                {{ title1 }}
            </div>
            <div class="div-logo" v-if="company_logo">
                <img :src="company_logo" alt=""
                    :style="{ maxHeight: headerSize + 'px', maxWidth: headerSize + 'px' }" />
            </div>
            <div class="div-header">
                {{ title2 }}
            </div>
        </div>
        <div>
            <div v-if="op_mode == 'PRODUCTS' && products.length >= 1">
                <!-- SOMENTE PRODUTOS -->
                <div v-if="products.length <= numCards" class="device_panel_body">
                    <PriceColumn :size="cardSize" :showUnits="showUnits" :products="products" :cardColor="cardColor"
                        :cardBarColor="bgColor" :txtColor="txtCardColor" :highlightedColor="highlightedColor" />
                </div>
                <div v-else class="device_panel_body">
                    <PriceCarousel :numCards="numCards" :cardSize="cardSize" :showUnits="showUnits"
                        :products="products.slice(0, productsHalf)" :cardColor="cardColor" :cardBarColor="bgColor"
                        :txtColor="txtCardColor" :highlightedColor="highlightedColor" />
                    <PriceCarousel :numCards="numCards" :cardSize="cardSize" :showUnits="showUnits"
                        :products="products.slice(productsHalf, products.length - 1)" :cardColor="cardColor"
                        :cardBarColor="bgColor" :txtColor="txtCardColor" :highlightedColor="highlightedColor" />
                </div>
            </div>
            <div v-else-if="['POSTERS', 'FULL_SCREEN_POSTERS'].includes(op_mode) && posters.length >= 1">
                <!-- SOMENTE OFERTAS -->
                <div :class="postersCLS">
                    <PostersCarousel :panelMode="op_mode" :posters="posters" :showUnits="showUnits"
                        :highlightedColor="highlightedColor" :cardColor="cardColor" />
                </div>
            </div>
            <div v-else-if="op_mode == 'MULTI_TASK' && (products.length >= 1 || posters.length >= 1)"
                class="device_panel_body">
                <!-- PRODUTOS E OFERTAS -->
                <PriceCarousel :numCards="numCards" :cardSize="cardSize" :showUnits="showUnits" :products="products"
                    :cardColor="cardColor" :cardBarColor="bgColor" :txtColor="txtCardColor"
                    :highlightedColor="highlightedColor" />
                <PostersCarousel :posters="posters" :panelMode="op_mode" :showUnits="showUnits"
                    :highlightedColor="highlightedColor" :cardColor="cardColor" />
            </div>
        </div>
    </div>
    <div class="semaforo semaforo__verde" v-if="apiConnection"></div>
    <div class="semaforo semaforo__vermelho" v-else></div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed } from "@vue/reactivity";
import { sleep } from "@/utils/functions";
import { encrypt, decrypt } from "@/utils/crypt";
import { is_not_empty_str } from "@/utils/functions";
import { getStatus, tokenService, loadDeviceDataService } from "@/services/painel_api";
import Logo from "@/components/Logo.vue";
import PriceColumn from "@/components/PriceColumn.vue";
import PriceCarousel from "@/components/PriceCarousel.vue";
import PostersCarousel from "@/components/PostersCarousel.vue";

//
//  Variáveis de Fluxo
//

//  Ferramentas Vue 
const route = useRoute();
const router = useRouter();
const store = useStore();

// Controle de Conexão
const websocket = ref(null);
const clientIP = ref(null);

//  Controle de Carregamento e Erros
const isDisabled = ref(false);
const alreadyConnected = ref(false);
const connectionSuccess = ref(false)
const isLoading = ref(true);
const apiConnection = ref(false);

//  Armazenamento de Dados
const productsHalf = ref(0);
const bg_image = ref(null);
const company_logo = ref(null);
const device_id = ref(null);
const device_panel = ref(null);
const title1 = ref("");
const title2 = ref("");
const showUnits = ref(true);
const op_mode = ref(null);
const products = ref(null);
const posters = ref(null);

// Controle de tempo
const CheckConnectionTime = ref(1000);
const RefreshTime = ref(30 * 1000);
const TryLoadTime = ref(5 * 1000);
const AsyncErrorTime = ref(3000);

// Controle de tamanho
const cardSize = ref();
const windowHeight = ref(window.innerHeight);
const bodyOccupation = ref(0.82);
const headerSize = ref(window.innerHeight * (1 - bodyOccupation.value));
const PcardHeight = ref(70);
const McardHeight = ref(100);
const GcardHeight = ref(130);

// Gerenciamento de Cores Padrão
// - Carregamento
const logoSize = ref("300");
const logoTransform = ref("translate(0,300) scale(0.0278,-0.0278)");
const logoColor = ref("#227777");

// - Página de Produtos
const defBgColor = "#D3FFDF"
const defHeaderColor = "#005B56";
const defTxtHeaderColor = "#FFFFFF";
const defCardColor = "#FFBE46";
const defTxtCardColor = "#1F1F1F";
const defHighlightedColor = "#DF0000";

const bgColor = ref(defBgColor);
const headerColor = ref(defHeaderColor);
const txtHeaderColor = ref(defTxtHeaderColor);
const cardColor = ref(defCardColor);
const txtCardColor = ref(defTxtCardColor);
const highlightedColor = ref(defHighlightedColor);

const getCardHeight = () => {
    switch (cardSize.value) {
        case "P":
            return PcardHeight.value;
        case "M":
            return McardHeight.value;
        case "G":
            return GcardHeight.value;
        default:
            return PcardHeight.value;
    }
}

// Computed 
const numCards = computed(() => {
    return Math.floor(((windowHeight.value * bodyOccupation.value) - 20) / getCardHeight());
})

const updateData = async () => {
    if (process.env.NODE_ENV === 'development') {
        console.log("Updating Data...");
    }

    if (!connectionSuccess.value || alreadyConnected.value) {
        return false;
    }

    var panel_idx = route.params.panel_num - 1;
    if (panel_idx < 0) {
        return false;
    }

    var responseData = null;
    try {
        responseData = await loadDeviceDataService(panel_idx);
    } catch (error) {
        // console.log(error);
        apiConnection.value = false;
        return false;
    }

    if (responseData === null || responseData == undefined) {
        return false;
    }

    apiConnection.value = true;
    device_id.value = responseData.device_id;
    store.dispatch("setPanels", responseData.panels);
    store.dispatch("setPlace", responseData.place);

    if (responseData?.panel === undefined) {
        await sleep(RefreshTime.value);
        location.reload();
        return;
    } else {
        device_panel.value = responseData.panel;
        showUnits.value = device_panel.value.show_units;
    }

    title1.value = device_panel.value?.t1;
    title2.value = device_panel.value?.t2;
    op_mode.value = device_panel.value.op_mode;
    products.value = device_panel.value.products;
    cardSize.value = device_panel.value.cards_size;
    posters.value = device_panel.value.posters;
    productsHalf.value = Math.floor(products.value.length / 2);

    const display_style = device_panel.value.style;
    const color_palette = display_style?.color_palette;

    if (color_palette !== undefined && color_palette !== null) {
        bgColor.value = color_palette.bg_color;
        headerColor.value = color_palette.header_color;
        txtHeaderColor.value = color_palette.txt_header_color;
        cardColor.value = color_palette.card_color;
        txtCardColor.value = color_palette.txt_card_color;
        highlightedColor.value = color_palette.highlighted_color;
    } else {
        bgColor.value = defBgColor;
        headerColor.value = defHeaderColor;
        txtHeaderColor.value = defTxtHeaderColor;
        cardColor.value = defCardColor;
        txtCardColor.value = defTxtCardColor;
        highlightedColor.value = defHighlightedColor;
    }

    company_logo.value = display_style?.logo?.url;
    bg_image.value = display_style?.bg_image?.url;

    isDisabled.value = !device_panel.value.is_enabled;
    return true
};

function onSocketOpen(evt) {
    if (process.env.NODE_ENV === 'development') {
        console.log("WebSocket Connected!");
    }
    apiConnection.value = true;

    var data = { cmd: "CLIENT_IP", client_ip: clientIP.value };
    websocket.value.send(JSON.stringify(data));
};

async function onSocketMessage(evt) {
    const data = JSON.parse(evt.data);
    const cmd = data.cmd;
    const device_ids = data.device_ids;

    if (cmd == "CONNECTION_SUCCESS") {
        connectionSuccess.value = true;
        return;
    } else if (cmd == "ALREADY_CONNECTED") {
        alreadyConnected.value = true;
        isLoading.value = false;
        return;
    }

    let exec = false;
    for (const idx in device_ids) {
        if (device_ids[idx] === device_id.value) {
            exec = true;
            break;
        }
    }

    if (exec === false) {
        return;
    }

    if (cmd === "UPDATE") {
        isLoading.value = true;
        await sleep(AsyncErrorTime.value);
        await updateData();
        isLoading.value = false;
    } else if (cmd === "RELOAD") {
        location.reload();
    } else {
        store.dispatch("setCMD", cmd);
    }
}

function onSockerError(evt) {
    if (process.env.NODE_ENV === 'development') {
        console.log("WebSocket Erro!");
    }
};

function onSocketClose(evt) {
    if (process.env.NODE_ENV === 'development') {
        console.log("WebSocket Closed!");
    }

    apiConnection.value = false;
    if (alreadyConnected.value) {
        return;
    }

    setTimeout(function () {
        init_api_socket();
    }, 1000);
};

function init_api_socket() {
    if (alreadyConnected.value) {
        return;
    }

    let socket_protocol = 'ws'
    const token = decrypt(store?.state?.token);
    const API_WS_HOST = process.env.VUE_APP_API_WS_HOST;

    if (process.env.VUE_APP_BASE_URL.includes('https')) {
        socket_protocol = 'wss';
    }

    var sockets_by_url = `${socket_protocol}://${API_WS_HOST}/ws/devices/?token=${token}`;
    websocket.value = new WebSocket(sockets_by_url);
    websocket.value.onopen = onSocketOpen;
    websocket.value.onmessage = onSocketMessage;
    websocket.value.onerror = onSockerError;
    websocket.value.onclose = onSocketClose;
};

async function checkAPI() {
    var responseData = null;
    try {
        responseData = await getStatus();
        apiConnection.value = true;
    } catch (error) {
        apiConnection.value = false;
        return;
    }

    clientIP.value = responseData?.client_ip;
}

async function getClientIP() {
    while (true) {
        await checkAPI();
        if (clientIP.value) {
            break;
        }
        await sleep(TryLoadTime.value);
    }
}

async function loadInfo() {
    if (alreadyConnected.value) {
        return;
    }

    let loaded = false;
    while (true) {
        loaded = await updateData();
        if (loaded) {
            break;
        }
        await sleep(TryLoadTime.value);
    }
    isLoading.value = false;
}

async function waitConnection() {
    while (!alreadyConnected.value && !connectionSuccess.value) {
        await sleep(CheckConnectionTime.value);
    }
}

onMounted(async () => {
    const token = store?.state?.token
    const accessToken = route.query?.access;
    if (!is_not_empty_str(accessToken) && !is_not_empty_str(token)) {
        router.push({ name: "login" });
    }
    if (is_not_empty_str(accessToken)) {
        router.push({ name: route.name, params: route.params });
    }

    if (is_not_empty_str(accessToken)) {
        while(true){
            try {
                const response = await tokenService(accessToken);
                const { auth_token } = response.data;
                store.dispatch('setToken', encrypt(auth_token));
                break;
            } catch (error) {
                const status = error.response?.status;
                if (status === 401 || status === 403) {
                    router.push({ name: "login" });
                    return;
                }
                sleep(TryLoadTime.value);
            }
        }
    }

    await getClientIP();
    init_api_socket();
    await waitConnection();
    await loadInfo();

    window.addEventListener("resize", () => {
        windowHeight.value = window.innerHeight
    });

    if (connectionSuccess.value) {
        setInterval(checkAPI, RefreshTime.value);
    }
});

const postersCLS = computed(() => {
    return op_mode.value == 'FULL_SCREEN_POSTERS' ? 'posters__full' : 'posters';
})
</script>

<style lang="scss" scoped>
.disable_page {
    display: flex;
    height: 100vh;
    background-color: #202020;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.loading_page {
    display: flex;
    height: 100vh;
    background-color: #202020;
    justify-content: center;
    align-items: center;
}

.logo {
    position: absolute;
}

.loader {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    display: inline-block;
    border-top: 10px solid #FFFDD2;
    border-right: 10px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border-bottom: 10px solid #FFFDD2;
    border-left: 10px solid transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.device_page {
    height: 100vh !important;
    width: 100vw !important;

    .price-list {
        margin: 10px;
    }
}

.device_panel_header {
    width: 100vw;
    height: 18vh;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    font-size: 64px;
    font-weight: bold;
    line-height: 0.9;
}

.div-header {
    width: 50vw;
}

.div-logo {
    width: 10vw;
}

.device_panel_body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 82vh;
}

.posters {
    padding-top: 4vh;

    &__full {
        padding-top: 0vh !important;
    }
}

.semaforo {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;

    &__verde {
        background-color: #00AAAA;
    }

    &__vermelho {
        background-color: #FF0000;
    }
}
</style>
