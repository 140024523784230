<template>
    <div class="slide" :style="{ color: props.txtColor }">
        <figure :class="posterFigure">
            <img :src="poster.image.url" alt="" :class="posterImage" />
            <figcaption v-if="props.poster.product?.brand && props.panelMode != 'FULL_SCREEN_POSTERS'" class="brand"
                :style="{ backgroundColor: props.bgColor }">
                {{ props.poster.product.brand }}
            </figcaption>
            <figcaption class="price" v-if="props.poster.product && props.panelMode != 'FULL_SCREEN_POSTERS'"
                :style="{ backgroundColor: props.bgColor }">
                <div :style="{ display: 'flex', alignItems: 'end' }">R$ {{ formattedPrice }}
                    <div v-if="props.showUnits" :style="{ display: 'flex', alignItems: 'end' }">
                        &nbsp/<div class="unit">{{ formattedUnit }}</div>
                    </div>
                </div>
            </figcaption>
        </figure>
        <div class="poster-header" v-if="props.poster.product && props.panelMode != 'FULL_SCREEN_POSTERS'">
            <p :class="productName" :style="{ backgroundColor: props.bgColor }">
                {{ props.poster.product.name }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { computed } from '@vue/reactivity';
import { formatUnit } from "@/utils/functions"

const props = defineProps({
    poster: Object,
    panelMode: String,
    showUnits: Boolean,
    txtColor: String,
    bgColor: String,
})

const formattedPrice = computed(() => {
    const priceNumber = props.poster.price.toFixed(2)
    const priceString = (priceNumber + "").replace(".", ",")
    return priceString
})

const formattedUnit = computed(() => {
    if ([undefined, null].includes(props.poster.product)) {
        return ""
    }
    return formatUnit(props.poster.product.measure_unit)
})

const productName = computed(() => {
    switch (props.panelMode) {
        case 'FULL_SCREEN_POSTERS':
            return 'product-name__none';
        case 'POSTERS':
            return 'product-name__big';
        default:
            return 'product-name';
    }
})

const posterImage = computed(() => {
    switch (props.panelMode) {
        case 'FULL_SCREEN_POSTERS':
            return 'poster-image__full';
        case 'POSTERS':
            return 'poster-image__big';
        default:
            return 'poster-image';
    }
})

const posterFigure = computed(() => {
    return props.panelMode == 'FULL_SCREEN_POSTERS' ? 'poster-figure__full' : 'poster-figure';
})

</script>

<style lang="scss" scoped>
.slide {
    font-weight: 600;
}

.poster-header {
    display: flex;
    justify-content: center;
    font-size: 50px;
    height: calc(10vh);
    margin-top: 10px;
}

.product-name {
    display: block;
    height: max-content;
    width: calc(((80vw - 40px)/2) - 10px);
    border-radius: 8px;

    &__big {
        height: max-content !important;
        width: calc(50vw) !important;
        border-radius: 8px !important;
    }

    &__none {
        display: none !important;
    }
}

.poster-image {
    height: calc(65vh);
    width: calc(((80vw - 40px)/2) - 10px);
    border-radius: 6%;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &__big {
        height: calc(80vh) !important;
        width: calc(50vw) !important;
        border-radius: 5% !important;
        border-top-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }

    &__full {
        display: block !important;
        height: calc(100vh) !important;
        width: calc(100vw) !important;
        border-radius: 0% !important;
    }
}

.poster-figure {
    display: inline-block;
    position: relative;
    margin-top: 10px;

    &__full {
        margin-top: 0px !important;
    }
}

.poster-figure figcaption {
    position: absolute;
    align-items: center;
    font-family: 'Cambria';
    font-weight: 500;
}

.brand {
    top: 0px;
    width: 60%;
    font-size: 35px;
    padding-bottom: 3px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.price {
    bottom: 0px;
    right: 0px;
    font-size: 70px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.unit {
    font-size: 30px;
}
</style>
